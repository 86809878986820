import i18n, { TFunction } from "i18next"
import { initReactI18next } from "react-i18next"

import en from "../translations/en.json"

export const resources = {
  en: {
    translation: en,
  },
} as const

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
})

export const t = i18n.t as TFunction<"translation">

export type I18nKey = keyof (typeof resources)["en"]["translation"]
