import { NotificationProps, showNotification } from "@mantine/notifications"

import { t } from "utils/i18n"

interface Props extends NotificationProps {}

const Notification = {
  success({ ...props }: Props) {
    const title = props.title !== undefined ? props.title : t("shared.success")

    showNotification({
      ...props,
      title,
    })
  },
  info({ variant = "default", ...props }: Props) {
    const title = props.title !== undefined ? props.title : t("shared.info")

    showNotification({
      ...props,
      title,
    })
  },
  warning({ variant = "default", ...props }: Props) {
    const title = props.title !== undefined ? props.title : t("shared.warning")

    showNotification({
      ...props,
      title,
    })
  },
  error({ variant = "default", ...props }: Props) {
    const title = props.title !== undefined ? props.title : t("shared.error")

    showNotification({
      ...props,
      title,
    })
  },
}

export default Notification
