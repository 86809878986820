import { Global, css } from "@emotion/react"
import tw from "twin.macro"

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        body {
          margin: 0;
          ${tw`m-0 text-darkest`}
          font-family: sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-smooth: never;
        }
      `}
    />
  )
}
