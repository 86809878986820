import { Outlet } from "react-router-dom"

import Loading from "pages/loading"

export default function App() {
  if (false) {
    return <Loading />
  }

  return <Outlet />
}
