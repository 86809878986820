const usePostSlackMessage = () => {
  return (channel: string, payload: object) =>
    fetch(channel, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(await response.text())
        }
      })
      .catch(console.error)
}

export default usePostSlackMessage
